import Vue from 'vue'
import Router from 'vue-router'
import VueRouter from 'vue-router'
import axios from "axios"
const API = axios.create({
  baseURL: "https://main-api.apptobus.com/",
})



Vue.use(Router)

const routes = [
  {
    path: '/',
    component: () => import('@/views/dashboard/Index'),
    children: [
      // Dashboard
      {
        name: 'LinesRoot',
        path: '',
        component: () => import('@/views/dashboard/pages/Lines'),
      },
      // Pages
      {
        name: 'User Profile',
        path: 'pages/user',
        component: () => import('@/views/dashboard/pages/UserProfile'),
      },
      {
        name: 'Notifications',
        path: 'components/notifications',
        component: () => import('@/views/dashboard/component/Notifications'),
      },
      {
        name: 'Icons',
        path: 'components/icons',
        component: () => import('@/views/dashboard/component/Icons'),
      },
      {
        name: 'Typography',
        path: 'components/typography',
        component: () => import('@/views/dashboard/component/Typography'),
      },
      // Tables
      {
        name: 'Regular Tables',
        path: 'tables/regular-tables',
        component: () => import('@/views/dashboard/tables/RegularTables'),
      },
      // Maps
      {
        name: 'Google Maps',
        path: 'maps/google-maps',
        component: () => import('@/views/dashboard/maps/GoogleMaps'),
      },
      // Upgrade
      {
        name: 'Upgrade',
        path: 'upgrade',
        component: () => import('@/views/dashboard/Upgrade'),
      },
      {
        name: 'Login',
        path: 'login',
        component: () => import('@/views/dashboard/Login'),
      },
      {
        name: 'LinesPage',
        path: 'pages/lines',
        component: () => import('@/views/dashboard/pages/Lines'),
      },
      {
        name: 'EditLines',
        path: 'pages/lines/edit/:id_line',
        component: () => import('@/views/dashboard/pages/EditLines'),
      },
    ],

  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
});

let isAuthenticated = localStorage.getItem('token');
// Middleware para manejar los cambios de ruta
router.beforeEach((to, from, next) => {
  isAuthenticated = localStorage.getItem('token');
  let token = {
    "token": isAuthenticated
  }

  if (to.name !== 'Login') {
    axios.post('https://main-api.apptobus.com/api/auth/me', token)
      .then(res => {
        next()
        console.log(res.data)
        localStorage.setItem('user', JSON.stringify(res.data));
      })
      .catch(err => {
        next('/login')
      })
  } else next()
})

// if (to.name !== 'Login' && !validator) next({ name: 'Login' })
// else next()

export default router